import * as React from 'react'
import Template from "../../components/template";
import {Link} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";

const Page = () => {
    return (
        <Template title="Закрытие BestPersons.ru">
            <div className="block text-sm breadcrumbs">
                <ul>
                    <li>
                        <Link to="/">Главная</Link>
                    </li>
                    <li>
                        Новости
                    </li>
                    <li><h2 className="font-bold">Закрытие BestPersons.ru</h2></li>
                </ul>
            </div>
            <div className="block space-y-2">
                <div className="flex justify-between">
                    <p>
                        <StaticImage alt="BestPersons.ru logo" src="../../images/bp/logo.png" className="my-2"/>
                    </p>
                    <p className="text-gray-500 text-sm">2021-08-07</p>
                </div>
                <p>К сожалению, <Link to="/projects/bp">проект BestPersons.ru</Link> закрыт.</p>
                <p>Это была отличная идея для своего времени, но, как показал наш опыт и опыт подобных зарубежных сервисов, в таком виде нежизнеспособная.</p>
                <p>Возможно, когда-нибудь, сайт откроется вновь, но это будет уже другой сервис.</p>
                <p>По всем вопросам можно писать через <Link to="/contact">форму</Link>.</p>
                <p>Спасибо всем, что были с нами!</p>
            </div>
        </Template>
    )
}

export default Page